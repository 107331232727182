.details{
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 20px;
    padding-top: 60px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details > h2{
    font-size: 2em;
    max-width: 80%;
    text-align: center;
}

.details-info-box{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 80px;
    row-gap: 40px;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .details{
        background-image: none !important;
        padding-top: 0px;
    }

    .details > h2 {
        font-size: 1.5em;
        max-width: 100%;
    }
}
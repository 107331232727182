.side-by-side{
    /* margin-top: 80px;
    padding-top: 80px; */
    display: flex;
    gap: 20px;
}

.video-section {
    position: relative;
    flex-basis: 60%;
}

@media only screen and (max-width: 800px) {
    .side-by-side{
        margin-top: 0px;
        flex-direction: column-reverse;
    }

    .video-section {
        flex-basis: auto;
        width: 100%;
        height: 300px;
    }
}
input[type="submit"]{
    width: 10vw;
    min-width: 200px;
    transition: 0.3s;
    cursor: pointer;
}

input[type="submit"]:hover{
    background-color: #00f8ba;
    transform: scale(1.02);
}
.get-it-button{
    display: flex;
    justify-content: center;
    transition: 0.3s;
    width: 250px;
    padding: 10px 0px;
    font-size: 15px;
    max-width: 40vw;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    align-self: center;
    margin-top: 15px;
    background-color: white;
    color: #002726;
}

.get-it-button-standard{
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: white;
    background-color: #5266df;
    height: 60px;
}

.get-it-button:hover{
    transform: scale(1.03);
}

.get-it-button img{
    width: 100%;
    border-radius: 10px;
}

@media only screen and (max-width: 800px) {
    .get-it-button{
        max-width: 50vw;
    }
}
#contact{
    width: 100%;
    margin-top: 80px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact h2{
    font-size: 2.5em;
    color: white;
}

#contact form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.form-submit-message{
    background-color: white;
    padding: 15px;
    border-radius: 20px;
}
#navbar{
    position: fixed;
    width: 100%;
    height: 90px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: 0.3s;
    z-index: 10;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

#navbar-content{
    width: 100vw;
    max-width: 1200px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    #navbar{
        height: auto !important;
    }
    #navbar-content{
        flex-direction: column;
        align-items: flex-start;
    }
}